import React, { useEffect, useState } from 'react';
import './suiet-wallet-kit-custom.css'; // Il tuo file CSS di personalizzazione
import { WalletProvider, useWallet, ConnectButton } from '@suiet/wallet-kit';
import '@suiet/wallet-kit/style.css';
import axios from 'axios';

import logo from './assets/logo.png';
import './navbar.css';

function fetchNFTCounts(walletAddress: string): Promise<{ boredApeCount: number; mutantApeCount: number }> {
  console.log("Fetching NFT counts for address:", walletAddress);
  return axios.get(`URL_API?walletAddress=${walletAddress}`)
    .then(response => {
      console.log("Risposta API:", response.data);
      // Qui elabori la risposta e restituisci i conteggi
      return { boredApeCount: 0, mutantApeCount: 0 }; // Esempio
    })
    .catch(error => {
      console.error('Errore nel fetch degli NFT:', error);
      return { boredApeCount: 0, mutantApeCount: 0 };
    });
}


function Navbar() {
  const wallet = useWallet();
  const [nftCounts, setNftCounts] = useState({ boredApeCount: 0, mutantApeCount: 0 });

  useEffect(() => {
    if (wallet.connected && wallet.address) {
      console.log("Stato di connessione del wallet:", wallet.connected);
      // Ora wallet.address è garantito essere una stringa
      fetchNFTCounts(wallet.address).then(data => {
        setNftCounts(data);
      }).catch(error => {
        console.error('Errore nel recuperare i conteggi NFT:', error);
        setNftCounts({ boredApeCount: 0, mutantApeCount: 0 });
      });
    }
  }, [wallet.connected, wallet.address]);
  

  return (
    <>
    <nav className="navbar">
      <div className="logo">
        <img src={logo} alt="logo" width="60" />
      </div>
      <div className="nav-item">
        <a href="./apes.html" className="hover:text-gray-500">Apes</a>
        <a href="#" className="hover:text-gray-500">Migrate</a>
        <a href="#" className="hover:text-gray-500">More</a>
      </div>
      <div className="nav-links">
        </div>
      </nav>
     <div className="nft-counts">
     <p>Bored Ape Count: {nftCounts.boredApeCount}</p>
     <p>Mutant Ape Count: {nftCounts.mutantApeCount}</p>
   </div>
 </>
);
}

export default Navbar;
