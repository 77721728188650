import React from 'react';
import './content.css';

// Definizione delle props che Content accetta
interface ContentProps {
  title: string;
  paragraphs: string[];
}

// Usa ContentProps come tipo delle props del componente
const Content: React.FC<ContentProps> = ({ title, paragraphs }) => {
  return (
    <div className="content">
      <h2 style={{ color: '#69d9e7', fontFamily: 'Ethnocentric', fontSize: '20px' , marginBottom: '20px'}}>{title}</h2>
      
            <p style={{marginBottom: '20px'}}>1) THE MIGRATION WILL BEGIN ON <b>MARCH XX 2024 AT XX:XX UTC </b> AND <b>THE DEADLINE WILL BE AFTER 33 DAYS FROM THE START.</b><br></br></p>
    
            <p style={{marginBottom: '20px'}}>2) IF YOU HAVE YOUR BORED APE SUI CLUB OR MUTANT APE SUI CLUB NFTs LISTED, DELIST THEM FROM THE MARKETPLACES TO MIGRATE TO APE SUI SOCIETY NFTs.<br></br></p>
  
            <p style={{marginBottom: '20px'}}>3) APE SUI SOCIETY NFTs THAT WON'T BE MIGRATED BEFORE THE DEADLINE WILL BE BURNED, OR USED FOR FUTURE PROMOTIONS & REWARDS TO THE COMMUNITY.<br></br></p>

            <p style={{marginBottom: '20px'}}>ALL THE BORED APE SUI CLUB & MUTANT APE SUI CLUB NFTs THAT WON'T BE MIGRATED <b>WILL BE USELESS IN FUTURE AND WILL NOT RECEIVE ANY BENEFITS FROM FUTURE PROJECT DEVELOPMENTS (REWARDS, STAKING, AIRDROP, ETC...).</b><br></br></p>
            <p style={{marginBottom: '20px'}}>5) IN ORDER TO MIGRATE YOU WILL NEED TO BURN YOUR BORED APE SUI CLUB OR MUTANT APE SUI CLUB NFTs WITHIN THIS WEBSITE.<br></br>
                <b>NFTS MUST BE BURNED ONLY THROUGH OUR DAPP.</b></p><br></br>
            <p style={{marginBottom: '20px'}}>6) PRESS THE <b>“MIGRATE”</b> BUTTON TO BURN YOUR BASCs/MASCs.  <b>THERE WILL BE A SMALL FEE OF 0.50 $SUI FOR EACH NFT YOU ARE GOING TO BURN.<br></br></b>
        AFTER DOING THE BURN YOU WILL AUTOMATICALLY RECEIVE THE NEW NFTs WITHIN FEW MINUTES DIRECTLY INTO YOUR WALLET.</p> 
      
    </div>
  );
}

export default Content;
