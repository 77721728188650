import React from 'react';
import './App.css'; // Assicurati che i percorsi siano corretti
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Content from './Content';
import Navbar from './Navbar';

const contentProps = {
  title: "APE SUI SOCIETY - MIGRATION RULES",
  paragraphs: [
    // You can add specific paragraphs here if needed
  ]
};

const queryClient = new QueryClient();

const centeredStyle: React.CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontFamily: 'Merri',
  fontSize: '14px',
  textAlign: 'center',
  width: '35%',
  overflowY: 'auto',
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <header className="App-header">
          <Navbar />
          <div style={centeredStyle}>
            <Content {...contentProps} />
          </div>
          {/* Overlay text */}
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'red',
            fontSize: '8em',
            fontWeight: 'bold',
            transform: 'rotate(-35deg)',
            pointerEvents: 'none',  // Allows interaction with elements under the overlay
            zIndex: 1000,  // Ensure it's on top
            opacity: 1  // Adjust opacity as needed
          }}>
            CLOSED
          </div>
        </header>
      </div>
    </QueryClientProvider>
  );
}

export default App;
